<template>
  <div class="mt10">
    <div class="box-card">
      <el-button
        type="primary"
        @click="handleAttach"
        :disabled="disabledUpload"
        >{{ btnTitle }}</el-button
      >
      <span class="tip"> <slot name="tip"></slot></span>
    </div>
    <el-row :gutter="20">
      <el-col :span="12" :xs="24" v-for="file in fileData" :key="file.eid">
        <el-card class="box-card mt10" shadow="hover">
          <div slot="header" class="tip">
            {{ file.typeName }}
            {{ moment(file.addTime).format("YYYY-MM-DD HH:mm:ss") }}
            <el-button type="text" size="small" @click="handleDelete(file)"
              >删除</el-button
            >
          </div>
          <el-image
            :src="file.coverUrl"
            v-if="file.coverUrl"
            @click="handleClick(file)"
            style="height: 150px"
            fit="fill"
            class="pointer"
          >
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>

          <div v-else>转换中...</div>
        </el-card>
      </el-col>
    </el-row>

    <Upload
      ref="Upload"
      sourceKey="sourceKey"
      :sourceId="sourceId"
      :accept="accept"
    ></Upload>
  </div>
</template>

<script>
import { listFile, delFile } from "@/api/attach/file.js";
import Upload from "./Upload.vue";

export default {
  data() {
    return {
      fileData: [],
      sourceKey: "",
      sourceId: "",
      tag: "",
    };
  },
  props: {
    maxCount: { type: Number, default: 100 },
    btnTitle: { type: String, default: "请上传图片或者视频" },
    accept: "",
  },
  components: {
    Upload,
  },
  computed: {
    disabledUpload() {
      return !(this.sourceId && this.maxCount > this.fileData.length);
    },
  },
  methods: {
    listFile() {
      this.loading = true;
      listFile(this.sourceKey, this.sourceId, this.tag).then((res) => {
        this.fileData = res;
        this.loading = false;
      });
    },
    handleClick(row) {
      if (row.isImage) {
        window.open(row.coverUrl);
      } else {
        window.open(`/cms/player/${row.eid}`, "Player");
      }
    },
    handleAttach() {
      this.$refs.Upload.show(this.sourceKey, this.sourceId, this.tag);
    },
    handleDelete(row) {
      this.$confirm(`是否要删除此附件吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.loading = true;
        delFile(row.eid).then(() => {
          this.loading = false;

          this.listFile();
          this.msgSuccess();
        });
      });
    },
    refresh() {
      this.listFile();
    },
    show(sourceKey, sourceId, tag) {
      this.sourceKey = sourceKey;
      this.sourceId = sourceId;
      this.tag = tag;
      if (this.sourceId) {
        this.listFile();
      }
    },
  },
};
</script>

<style></style>

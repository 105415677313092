<template>
  <div class="mg10 page-box">
    <el-row :gutter="20">
      <el-col :span="12" :xs="24">
        <el-card>
          <div slot="header">1.编辑展品或服务信息</div>
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="110px"
            v-loading="loading"
          >
            <el-row>
              <el-col :span="24">
                <el-form-item label="展品名称" prop="name">
                  <el-input
                    v-model="form.name"
                    placeholder="请输入展品名称"
                    maxlength="100"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="展品介绍" prop="introduction">
                  <el-input
                    type="textarea"
                    :rows="3"
                    resize="none"
                    v-model="form.introduction"
                    placeholder="展品介绍"
                    maxlength="500"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>

              <el-col :span="12" :xs="24">
                <el-form-item label="品牌" prop="brand">
                  <el-input
                    v-model="form.brand"
                    placeholder="请输入品牌"
                    maxlength="20"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>

              <el-col :span="12" :xs="24">
                <el-form-item label="规格" prop="norms">
                  <el-input
                    v-model="form.norms"
                    placeholder="请输入规格"
                    maxlength="20"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>

              <el-col :span="12" :xs="24">
                <el-form-item label="价格(元)" prop="price">
                  <el-input
                    v-model="form.price"
                    placeholder="请输入价格"
                    maxlength="9"
                    show-word-limit
                  />
                </el-form-item>
              </el-col>

              <el-col :span="12" :xs="24">
                <el-form-item label="产地/服务地区" prop="place">
                  <el-tag closable v-show="form.place" @close="onPlaceTag">
                    {{ form.place }}
                  </el-tag>
                  <el-cascader
                    v-show="!form.place"
                    :props="propsPlace"
                    @change="handlePlace"
                    ref="cascaderPlace"
                    style="width: 100%"
                  ></el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <span>
            <el-button type="primary" v-if="this.isMobile()" @click="handleBack"
              >返回主页</el-button
            >
            <el-button type="primary" @click="submit">保 存</el-button>
          </span>
        </el-card>
      </el-col>
      <el-col :span="12" :xs="24">
        <el-card>
          <div slot="header">
            2.上传附件 <b v-show="!uniqueId">请保存栏目1的数据</b>
          </div>
          <AttachVod
            ref="AttachVodCover"
            :maxCount="1"
            btnTitle="请您上传宣传的封面"
            accept="image/jpeg,image/png,image/bmp,image/gif"
          >
            <template v-slot:tip>
              您只能上传<b>一个</b>宣传的封面（图片）
            </template>
          </AttachVod>

          <AttachVod
            ref="AttachVodAd"
            btnTitle="请上传简介图片或者宣传展示视频"
          >
            <template v-slot:tip>
              如果您上传展品或服务简介图片或者宣传展示视频
            </template>
          </AttachVod>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getGood, updateGood } from "@/api/goods/good.js";
import { listAreaByParent } from "@/api/cms/area.js";
import AttachVod from "@/components/AttachVod/Index.vue";

export default {
  name: "Edit",
  data() {
    return {
      visible: false,
      loading: false,
      uniqueId: "",
      form: {},
      // 表单校验
      rules: {
        name: [{ required: true, message: "请输入展品名称", trigger: "blur" }],
        introduction: [
          { required: true, message: "请输入展品介绍", trigger: "blur" },
        ],
      },
      propsPlace: {
        lazy: true,
        lazyLoad: this.lazyLoadPlace,
      },
    };
  },
  components: {
    AttachVod,
  },
  methods: {
    handleBack() {
      this.$router.push("/goods/me");
    },
    getGood() {
      this.visible = true;
      this.loading = true;
      getGood(this.$route.params.goodEId).then((res) => {
        this.form = res;
        this.uniqueId = res.uniqueId;
        this.loading = false;

        this.refreshAttach();
      });
    },
    refreshAttach() {
      this.$refs.AttachVodCover.show("Goods", this.uniqueId, "Cover");
      this.$refs.AttachVodAd.show("Goods", this.uniqueId, "Ad");
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.doSave();
        } else {
          return false;
        }
      });
    },
    doSave() {
      this.loading = true;
      updateGood(this.$route.params.goodEId, this.form)
        .then((res) => {
          this.msgSuccess("保存成功，请您及时上传相关附件！");
          this.loading = false;
          this.visible = false;

          if (this.$route.params.goodEId) {
            return;
          }

          this.$router.push(`/goods/main/${res.goodEId}`);
          this.uniqueId = res.uniqueId;
          this.refreshAttach();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    lazyLoadPlace(node, resolve) {
      var pid = node.value;
      if (pid == null) {
        pid = 0;
      }
      listAreaByParent(pid).then((res) => {
        const nodes = res.map((val) => ({
          value: val.id,
          label: val.name,
          leaf: node.level > 0,
        }));
        resolve(nodes);
      });
    },
    handlePlace() {
      this.form.place = this.$refs.cascaderPlace.getCheckedNodes()[0].label;
    },
    onPlaceTag() {
      this.form.place = null;
    },
  },
  created() {
    this.getGood();
  },
};
</script>

<style lang="scss" scoped></style>

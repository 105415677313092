import request from "@/utils/request";

export function getToken(sourceKey, sourceId, tag, fileName, isImage) {
  return request({
    url: `/attach/token/${sourceKey}/${sourceId}/${tag}`,
    method: "post",
    data: { fileName: fileName, isImage: isImage },
  });
}

export function listFile(sourceKey, sourceId, tag) {
  return request({
    url: `/attach/list/${sourceKey}/${sourceId}/${tag}`,
    method: "get",
  });
}

export function syncReq(sourceKey, sourceId, attachKey) {
  return request({
    url: `/attach/sync/${sourceKey}/${sourceId}/${attachKey}`,
    method: "get",
  });
}

export function delFile(eid) {
  return request({
    url: `/attach/file/${eid}`,
    method: "delete",
  });
}

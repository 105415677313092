<template>
  <el-dialog
    title="上传附件（图片或者视频）"
    :visible.sync="visible"
    width="60%"
    :close-on-click-modal="false"
  >
    <div>
      <div class="item">
        <el-alert
          title="上传附件说明：1、选择文件；2、开始上传。（请上传
          小于1G的视频，小于10M的图片！）"
          type="warning"
        >
        </el-alert>
      </div>
      <div class="item">
        <input
          type="file"
          id="fileUpload"
          :accept="accept"
          @change="fileChange($event)"
        />
        <label class="status"
          >上传状态: <span>{{ statusText }}</span></label
        >
      </div>

      <div class="item">
        <el-button
          type="primary"
          round
          @click="authUpload"
          :disabled="uploadDisabled"
          >开始上传</el-button
        >

        <el-progress
          :text-inside="true"
          :stroke-width="20"
          :percentage="authProgress"
          status="success"
        ></el-progress>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getToken, syncReq } from "@/api/attach/file.js";

export default {
  data() {
    return {
      region: "cn-shanghai",
      userId: "1108063060633096",
      authProgress: 0,
      uploadDisabled: true,
      uploader: null,
      statusText: "",

      visible: false,
      sourceKey: "",
      sourceId: 0,
    };
  },
  props: {
    accept: "",
  },
  methods: {
    fileChange(e) {
      let file = e.target.files[0];
      if (!file) {
        alert("请先选择需要上传的文件!");
        return;
      }

      var userData = '{"Vod":{}}';
      if (this.uploader) {
        this.uploader.stopUpload();
        this.authProgress = 0;
        this.statusText = "";
      }
      this.uploader = this.createUploader();

      this.uploader.addFile(file, null, null, null, userData);
      this.uploadDisabled = false;
    },
    authUpload() {
      // 然后调用 startUpload 方法, 开始上传
      if (this.uploader !== null) {
        this.uploader.startUpload();
        this.uploadDisabled = true;
      }
    },
    createUploader(type) {
      let self = this;
      let uploader = new AliyunUpload.Vod({
        region: self.region,
        userId: self.userId,
        localCheckpoint: true, //此参数是禁用服务端缓存，不影响断点续传
        // 添加文件成功
        addFileSuccess: function (uploadInfo) {
          self.uploadDisabled = false;
          self.statusText = "添加文件成功, 等待上传...";
          this.fileName = uploadInfo.file.name;
        },
        // 开始上传
        onUploadstarted: function (uploadInfo) {
          getToken(
            self.sourceKey,
            self.sourceId,
            self.tag,
            uploadInfo.file.name,
            uploadInfo.isImage
          ).then((data) => {
            self.attachId = data.attachId;
            uploader.setUploadAuthAndAddress(
              uploadInfo,
              data.uploadAuth,
              data.uploadAddress,
              data.attachKey
            );
          });

          self.statusText = "文件开始上传...";
        },
        // 文件上传成功
        onUploadSucceed: function (uploadInfo) {
          console.log("文件上传成功", uploadInfo);
          self.statusText = "文件上传成功!";
          syncReq(self.sourceKey, self.sourceId, uploadInfo.videoId);
        },
        // 文件上传失败
        onUploadFailed: function (uploadInfo, code, message) {
          self.statusText = `文件上传失败!code:${code}, message:${message}`;
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function (uploadInfo, totalSize, progress) {
          let progressPercent = Math.ceil(progress * 100);
          self.authProgress = progressPercent;
          self.statusText = "文件上传中...";
        },
        // 全部文件上传结束
        onUploadEnd: function (uploadInfo) {
          console.log("onUploadEnd: uploaded all the files");
          self.statusText = "文件上传完毕";
          //请求一下。
          self.visible = false;
          self.$parent.refresh();
        },
      });
      return uploader;
    },

    reset() {
      this.uploader = null;
      this.statusText = "[未开始]";
      this.fileName = "";
      this.authProgress = 0;
      this.uploadDisabled = true;
    },
    show(sourceKey, sourceId, tag) {
      this.reset();

      this.sourceKey = sourceKey;
      this.sourceId = sourceId;
      this.tag = tag;
      this.visible = true;
    },
  },
};
</script>
<style scoped>
.item {
  margin-bottom: 30px;
  font-size: 14px;
}
</style>
